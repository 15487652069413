import { Fragment } from "react"

import Layout from "../components/Layout"
import SEO from "../components/seo"
import DefaultHeader from "../components/DefaultHeader"
import Section from "../components/Section"
import Background from "../components/Background"
import { centerHorizontally } from "../styles/constants"
import { Heading } from "rebass/styled-components"
import Footer from "../components/Footer"
import * as React from "react"

const LicenseAgreementPage = () => (
  <Layout>
    <SEO title="License Agreement" />
    <DefaultHeader />
    <div>
      <Section.Container id="licenseAgreement" Background={Background}>
        <Fragment>
          <div style={centerHorizontally}>
            <Heading
              as="h3"
              color="primary"
              fontSize={[3, 4]}
              mb={[2, 3, 4]}
              textAlign="center"
              style={centerHorizontally}
            >
              End-User License Agreement (EULA) of <i>ChallengeMe App</i>
            </Heading>
            <p>
              This End-User License Agreement ("EULA") is a legal agreement
              between you and <i>BurritoApps</i>
            </p>

            <p>
              This EULA agreement governs your acquisition and use of our{" "}
              <i>ChallengeMe App</i> software ("Software") directly from{" "}
              <i>BurritoApps</i> or indirectly through a <i>BurritoApps</i>{" "}
              authorized reseller or distributor (a "Reseller").
            </p>

            <p>
              Please read this EULA agreement carefully before completing the
              registration/installation process and using the{" "}
              <i>ChallengeMe App</i> software. It provides a license to use the{" "}
              <i>ChallengeMe App</i> software and contains warranty information
              and liability disclaimers.
            </p>

            <p>
              By clicking "accept" or installing and/or using the{" "}
              <i>ChallengeMe App</i> software, you are confirming your
              acceptance of the Software and agreeing to become bound by the
              terms of this EULA agreement.
            </p>

            <p>
              If you are entering into this EULA agreement on behalf of a
              company or other legal entity, you represent that you have the
              authority to bind such entity and its affiliates to these terms
              and conditions. If you do not have such authority or if you do not
              agree with the terms and conditions of this EULA agreement, do not
              install or use the Software, and you must not accept this EULA
              agreement.
            </p>

            <p>
              This EULA agreement shall apply only to the Software supplied by{" "}
              <i>BurritoApps</i> herewith regardless of whether other software
              is referred to or described herein. The terms also apply to any{" "}
              <i>BurritoApps</i> updates, supplements, Internet-based services,
              and support services for the Software, unless other terms
              accompany those items on delivery. If so, those terms apply.
            </p>

            <Heading
              as="h3"
              color="primary"
              fontSize={[3, 4]}
              mb={[2, 3, 4]}
              textAlign="center"
              style={centerHorizontally}
            >
              License Grant
            </Heading>

            <p>
              <i>BurritoApps</i> hereby grants you a personal, non-transferable,
              non-exclusive licence to use the <i>ChallengeMe App</i> software
              on your devices in accordance with the terms of this EULA
              agreement.
            </p>

            <p>
              You are permitted to load the <i>ChallengeMe App</i> software (for
              example a PC, laptop, mobile or tablet) under your control. You
              are responsible for ensuring your device meets the minimum
              requirements of the <i>ChallengeMe App</i> software.
            </p>

            <p>You are not permitted to:</p>

            <ul>
              <li>
                Edit, alter, modify, adapt, translate or otherwise change the
                whole or any part of the Software nor permit the whole or any
                part of the Software to be combined with or become incorporated
                in any other software, nor decompile, disassemble or reverse
                engineer the Software or attempt to do any such things
              </li>
              <li>
                Reproduce, copy, distribute, resell or otherwise use the
                Software for any commercial purpose
              </li>
              <li>
                Allow any third party to use the Software on behalf of or for
                the benefit of any third party
              </li>
              <li>
                Use the Software in any way which breaches any applicable local,
                national or international law
              </li>
              <li>
                Use the Software for any purpose that <i>BurritoApps</i>{" "}
                considers is a breach of this EULA agreement
              </li>
              <li>Produce or upload any objectionable content</li>
            </ul>

            <p>
              There is no tolerance for objectionable content or abusive users,
              neither on this Site nor in the <i>ChallengeMe App</i> at any
              time. Users who get reported for violating any of the above rules
              will be deleted immediately.
            </p>

            <Heading
              as="h3"
              color="primary"
              fontSize={[3, 4]}
              mb={[2, 3, 4]}
              textAlign="center"
              style={centerHorizontally}
            >
              Intellectual Property and Ownership
            </Heading>

            <p>
              <i>BurritoApps</i> shall at all times retain ownership of the
              Software as originally downloaded by you and all subsequent
              downloads of the Software by you. The Software (and the copyright,
              and other intellectual property rights of whatever nature in the
              Software, including any modifications made thereto) are and shall
              remain the property of <i>BurritoApps</i>.
            </p>

            <p>
              <i>BurritoApps</i> reserves the right to grant licences to use the
              Software to third parties.
            </p>

            <Heading
              as="h3"
              color="primary"
              fontSize={[3, 4]}
              mb={[2, 3, 4]}
              textAlign="center"
              style={centerHorizontally}
            >
              Termination
            </Heading>

            <p>
              This EULA agreement is effective from the date you first use the
              Software and shall continue until terminated. You may terminate it
              at any time upon written notice to <i>BurritoApps</i>.
            </p>

            <p>
              It will also terminate immediately if you fail to comply with any
              term of this EULA agreement. Upon such termination, the licenses
              granted by this EULA agreement will immediately terminate and you
              agree to stop all access and use of the Software. The provisions
              that by their nature continue and survive will survive any
              termination of this EULA agreement.
            </p>

            <Heading
              as="h3"
              color="primary"
              fontSize={[3, 4]}
              mb={[2, 3, 4]}
              textAlign="center"
              style={centerHorizontally}
            >
              Governing Law
            </Heading>

            <p>
              This EULA agreement, and any dispute arising out of or in
              connection with this EULA agreement, shall be governed by and
              construed in accordance with the laws of Germany.
            </p>
            <p>
              This EULA was created by{" "}
              <a href="https://www.eulatemplate.com">EULA Template</a> for{" "}
              <i>ChallengeMe App</i>.
            </p>
          </div>
        </Fragment>
      </Section.Container>
    </div>
    <Footer />
  </Layout>
)

export default LicenseAgreementPage
